<template>
  <a-drawer
    title="详情"
    placement="right"
    :destroyOnClose="true"
    :width="1200"
    :visible="visible"
    @close="onClose">
    <p-table
      ref="table"
      row-key="id"
      extraHeight="200"
      :sourceData="getDataApi"
      :pagination="{
        hideOnSinglePage: true,
      }"
      :columns="columns"
    >
      <template slot="action" slot-scope="text, record">
        <a-button type="link" @click="handleDetails(record)">详情</a-button>
      </template>
    </p-table>
    <financeDetails ref="financeDetails" />
  </a-drawer>
</template>

<script>
import financeDetails from './finance-details.vue'
import { getDetails } from '@/api/invoice'
import moment from 'moment'
import { typeOptions as salaryTypes } from '@/components/user-salary/utils'

export default {
  name: 'Details',
  components: {
    financeDetails
  },
  data () {
    return {
      visible: false,
      rowItem: {},
      arrList: ['会费开票', '会员']
    }
  },
  computed: {
    columns () {
      const column = !this.arrList.includes(this.rowItem.type)
        ? [
            {
              title: '名称',
              dataIndex: 'name',
              key: 'name',
              width: 350
            },
            {
              title: '发薪类型',
              dataIndex: 'flag',
              key: 'flag',
              customRender: (t, r) => {
                const type = salaryTypes.find((item) => item.value == t)?.label
                return type
              }
            },
            {
              title: '最后申请发薪时间',
              dataIndex: 'closeTime',
              key: 'closeTime',
              customRender: (t) => t && moment(t).format('YYYY-MM-DD HH:mm:ss')
            },
            {
              title: '申请结算时间',
              dataIndex: 'applyTime',
              key: 'applyTime',
              customRender: (t) => t && moment(t).format('YYYY-MM-DD HH:mm:ss')
            },
            {
              title: '操作',
              dataIndex: 'action',
              width: 140,
              key: 'action',
              scopedSlots: { customRender: 'action' }
            }
          ]
        : [
            {
              title: '订单编号',
              dataIndex: 'orderNumber',
              key: 'orderNumber'
            },

            {
              title: '产品名称',
              dataIndex: 'productName',
              key: 'productName'
            },
            {
              title: '金额（￥）',
              dataIndex: 'price',
              key: 'price'
            },
            {
              title: '订单交易时间',
              dataIndex: 'createTime',
              key: 'createTime',
              customRender: (t) => t && moment(t).format('YYYY-MM-DD HH:mm:ss')
            }
          ]
      return [
        {
          title: '序号',
          dataIndex: 'index',
          key: 'index',
          width: 60,
          customRender: (t, r, i) => i + 1
        },
        ...column
      ]
    }
  },
  methods: {
    show (rowItem) {
      this.rowItem = { ...rowItem }
      this.visible = true
    },
    onClose () {
      this.rowItem = {}
      this.visible = false
    },
    getDataApi (params) {
      if (this.rowItem.id) {
        return new Promise((resolve) => {
          getDetails({
            type: this.rowItem.type,
            id: this.rowItem.id
          }).then((res) => {
            resolve({
              data: res.data[!this.arrList.includes(this.rowItem.type) ? 'salaryGroupArrayList' : 'memberBuyOrderList']
            })
          })
        })
      } else {
        return Promise.resolve()
      }
    },
    handleDetails (rowItem) {
      this.$refs.financeDetails.showModal(rowItem)
    }
  }
}
</script>
